/* swiper */
.pressSwiper {
    width: 100%;
    height: 100%;
  }
  
  .pressSwiper div .swiper-slide {
    text-align: center;
    font-size: 18px;
    /* background: red; */
  }
  
  .pressSwiper div .swiper-slide {
    width: 100%;
    height: 100%;
  }
  
  /* @media only screen and (max-width: 639) {
    .pressSwiper div .swiper-slide:nth-child(n) {
      width: 578px;
      height: 320px;
    }
    .pressSwiper div .swiper-slide:nth-child(2n) {
      width: 578px;
      height: 320px;
    }
    .pressSwiper div .swiper-slide:nth-child(3n) {
      width: 578px;
      height: 320px;
    }
    .pressSwiper div .swiper-slide:nth-child(4n) {
      width: 578px;
      height: 320px;
    }
    .pressSwiper div .swiper-slide:nth-child(5n) {
      width: 578px;
      height: 320px;
    }
  } */
  
  .pressSwiper div .swiper-slide:nth-child(n) {
    width: 728px;
    height: 475px;
  }
  
  .pressSwiper div .swiper-slide:nth-child(2n) {
    width: 728px;
    height: 475px;
  }
  
  .pressSwiper div .swiper-slide:nth-child(3n) {
    width: 728px;
    height: 475px;
  }
  
  .pressSwiper div .swiper-slide:nth-child(4n) {
    width: 728px;
    height: 475px;
  }
  
  .pressSwiper div .swiper-slide:nth-child(5n) {
    width: 728px;
    height: 475px;
  }
  
  .pressMobaSwiper {
    width: 100%;
    height: 100%;
  }
  
  .pressMobaSwiper div .swiper-slide {
    text-align: center;
    font-size: 18px;
  }
  
  .pressMobaSwiper div .swiper-slide {
    width: 100%;
    height: 100%;
  }
  
  .pressMobaSwiper div .swiper-slide:nth-child(n) {
    width: 200px;
    height: 420px;
  }
  
  .pressMobaSwiper div .swiper-slide:nth-child(2n) {
    width: 200px;
    height: 420px;
  }
  
  .pressMobaSwiper div .swiper-slide:nth-child(3n) {
    width: 200px;
    height: 420px;
  }
  
  .pressMobaSwiper div .swiper-slide:nth-child(4n) {
    width: 200px;
    height: 420px;
  }
  
  .pressMobaSwiper div .swiper-slide:nth-child(5n) {
    width: 200px;
    height: 420px;
  }