@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "Rubik-regular";
  src: local("Rubik-regular"), url(./assets//font/Rubik-regular.ttf);
}

@font-face {
  font-family: "Rubik-ExtraBold";
  src: local("Rubik-ExtraBold"), url(./assets//font/Rubik-ExtraBold.ttf);
}

@font-face {
  font-family: "Nasalization";
  src: local("Nasalization"), url(./assets//font/Nasalization.ttf);
}

@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800&display=swap");

#root {
  --primary: #0abab5;
  --secondary: #0ce6df;
  --accent: #718096;
  --background: #fff;
  --main: #0d0106;
  --header: #2d3748;
}

body {
  margin: 0;
  font-family: "Rubik", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Rubik-ExtraBold {
  font-family: "Rubik-ExtraBold";
}

.nasalization {
  font-family: "Nasalization";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.dotNavigation:hover {
  background: linear-gradient(180deg, #c9dfff 0%, #a19fff 100%);
  box-shadow: 0px 0px 50px rgba(255, 255, 255, 0.8);
}

.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: transparent;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}
.tooltip:hover {
  background: linear-gradient(180deg, #c9dfff 0%, #a19fff 100%);
  box-shadow: 0px 0px 50px rgba(255, 255, 255, 0.8);
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
